import React, {Component} from 'react';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import {connect} from "react-redux";
import {
    text_geral,
    label
    
} from './FormulaireSelectionProjet.module.scss';
import {checkMot} from "../../../../ressources/functionJS/checkMot";


class FormulaireSelectionProjet extends Component {

    state={
        projetId:null,
        preconfigurerProjetId:this.props.premier_valeur_filtre_projet,
        selectedProjectId:this.props.recupererListeImportFilters,
    }
    componentDidMount() {
        
        if(this.props.preconfigurerProjetId!==undefined && this.props.preconfigurerProjetId!==null){
            this.setState({...this.state, 
                projetId:this.props.preconfigurerProjetId,
                preconfigurerProjetId:this.props.preconfigurerProjetId,
            })
        } else if (this.state.projetId === undefined || this.state.projetId === null){
            this.setState({
                ...this.state,
                projetId:this.props.premier_valeur_filtre_projet
            });
            this.props.gererProjetId(this.props.premier_valeur_filtre_projet);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.preconfigurerProjetId!==prevProps.preconfigurerProjetId){
            this.setState({...this.state,
                projetId:this.props.preconfigurerProjetId,
                preconfigurerProjetId:this.props.preconfigurerProjetId,});
        }else if (this.props.premier_valeur_filtre_projet!== undefined && (this.state.projetId === undefined || this.state.projetId === null)){
            this.setState({...this.state, projetId:this.props.premier_valeur_filtre_projet});
            this.props.gererProjetId(this.props.premier_valeur_filtre_projet);
    }
    }
    gererProjetId = (event) => {
        /**
         * gère id du parent en fonction de l'option sélectionnée
         */
        if (event.target.value === -1 || event.target.value === "-1"){
            this.setState({...this.state,projetId:null});
        }else{
            let newProjetId = event.target.value;
            this.setState({...this.state,projetId:newProjetId});
        }
        this.props.gererProjetId(event.target.value);
    }
    handleChange = (event) => {
        this.setState({ selectedProjectId: event.target.value });
    }
    render(){
        console.log("export filtre project",this.props.premier_valeur_filtre_projet )
        // récupérer liste des projets avec this.props.listeProjets
        // créer un formulaire pour ajouter un parent à un projet sous forme de liste déroulante
        return(
            <Auxiliaire>
                <label className={[label].join(" ")} htmlFor="inlineFormCustomSelect">
                    {checkMot("projet")}
                    {/*Affiche l'id et le nom du projet, si le projet n'a pas de parent on affiche "Projet Principal"*/}
                    {/*{console.log('preconfigurerProjetId',this.props.preconfigurerProjetId)}*/}
                    {/*{this.props.preconfigurerProjetId!==undefined*/}
                    {/*    ?<span>{' id:' + this.state.projetId}</span>*/}
                    {/*    :null*/}
                    {/*}*/}
                </label>
                <select
                    className={[s.custom_select, text_geral, s.card_body].join(" ")}
                    onChange={(event) =>this.gererProjetId(event)}
                    disabled={this.props.affichageProfil===true}
                >
                   <option disabled={true} selected={true}>Sélectionné un projet</option>
                    {Object.keys(this.props.listeProjets).map(indListeProjets=>{
                        return <option key={this.props.listeProjets[indListeProjets].project_id}
                                       value={this.props.listeProjets[indListeProjets].project_id}
                                       selected={this.state.preconfigurerProjetId===this.props.listeProjets[indListeProjets].project_id}>
                            id: {this.props.listeProjets[indListeProjets].project_id+", name: "+this.props.listeProjets[indListeProjets].project_name}
                        </option>
                    })}
                </select>
            </Auxiliaire>
        );
    }
}
const mapStateToProps = state => {
    return {
        listeProjets: state.authentifie.projects_list,
        premier_valeur_filtre_projet: state.authentifie.filtre_projects_list_id[0],
    };
}

export default connect(mapStateToProps)(FormulaireSelectionProjet);
