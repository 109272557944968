import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet from "../../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import Bouton from "../../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../../hoc/AttenteRequete/AttenteRequete";

import {IsArrayString} from "../../../../../ressources/functionJS/IsArrayString";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import * as requestType from '../../../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_pattern from "../../../../../ressources/glossaires/glossaire_pattern";
import * as type_btn from "../../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../../../store/actions";

import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireRapportGabarit.module.scss';
import {AiOutlineCheck} from "react-icons/ai";
import Collapse from "../../../../../hoc/Collapse/Collapse"
import FormulaireSelectionDateHeureUnix
    from "../../../../Graphe/ProfilGraphe/FormulaireGraphe/FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix";
import FormulaireListesObjetsRapport from "./FormulaireListeObjetsRapport/FormulaireListeObjetsRapport";
import FormulaireSelectionTimeZone from "../../../../UI/FormulaireSelectionTimeZone/FormulaireSelectionTimeZone";
import FormulaireSelectionEmailgroup
    from "../../../../Capteur/Ajouter/FormulaireSelectionEmailgroup/FormulaireSelectionEmailgroup";
import FormulaireSaisieDuree from "../../../../UI/FormulaireSaisieDurée/FormulaireSaisieDuree";

class FormulaireRapportGabarit extends Component {
    state = {
        //Information générale
        // project_id: this.props.preconfigurerRapportGabarit !== undefined && this.props.preconfigurerRapportGabarit.project_id !== undefined ? this.props.preconfigurerRapportGabarit.project_id : null,
        name: "",
        time_zone: "Europe/Paris",
        email_group_id: null,
        interval_next_generation: null,
        last_unixtimestamp_run: null,
        next_unixtimestamp_run: null,
        mode: 0,
        enable: 0,
        publication_auto: 0,
        pdf_result: 0,
        number_of_days: 0,
        show_summary: false

    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerRapportGabarit === undefined) {
            this.setState({
                //paramètre par défaut du rapport gabarit(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerRapportGabarit !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerRapportGabarit,
            })
        }
        if (this.props.configurationRapportGabarit !== undefined) {
            this.setState({...this.props.configurationRapportGabarit})
        } else {
            this.setState({
                ...this.state,
                graphical_charter: {
                    page_header: {omit_cover_page: 1, objects_list: []},
                    page_footer: {omit_cover_page: 1, objects_list: []},
                    font_style: {
                        family: "Montserrat",
                        style: "italic",
                        weight: 2,
                        line_height: "normal",
                        size: 2
                    },
                },
                cover_page: {
                    cover_centered: 1,
                    objects_list: []
                },
                body_page: {objects_list: []}
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigurerRapportGabarit !== this.props.preconfigurerRapportGabarit && this.props.preconfigurerRapportGabarit === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerRapportGabarit !== undefined && !jsDictionaryEquals(prevProps.preconfigurerRapportGabarit, this.props.preconfigurerRapportGabarit)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerRapportGabarit,
            })
        }
        if (this.props !== undefined && this.props.configurationRapportGabarit !== undefined && (!jsDictionaryEquals(this.props.configurationRapportGabarit, prevProps.configurationRapportGabarit)
            || this.props.preconfigurerRapportGabarit.file_path !== prevProps.preconfigurerRapportGabarit.file_path)) {
            this.setState({
                ...this.state,
                graphical_charter: this.props.configurationRapportGabarit.graphical_charter,
                cover_page: this.props.configurationRapportGabarit.cover_page,
                body_page: this.props.configurationRapportGabarit.body_page,
                show_summary: this.props.configurationRapportGabarit.summary !== undefined
            })
        }
    }

    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API


        let json_config = {

            graphical_charter: {
                ...this.state.graphical_charter,
                font_style: {
                    ...this.state.graphical_charter.font_style,
                    weight: parseInt(this.state.graphical_charter.font_style.weight),
                    size: parseInt(this.state.graphical_charter.font_style.size)
                }
            },
            cover_page: {
                ...this.state.cover_page,
            },
            body_page: {
                ...this.state.body_page,
            }
        }
        if (this.state.show_summary === true) {
            json_config["summary"] = {
                "objects_list": [
                    {
                        "summary_title": "Sommaire",
                        "family": "Montserrat",
                        "size": "italic",
                        "bold": true,
                        "italic": false,
                        "pagebreak": true
                    }
                ]
            }
        } else {
            delete json_config["summary"]
        }

        let formulaireRapportGabarit = {
            //clé du formulaire de rapport gabarit de l'api
            template_report_id: parseInt(this.state.template_report_id),
            project_id: parseInt(this.state.project_id),
            name: this.state.name,
            time_zone: this.state.time_zone,
            email_group_id: parseInt(this.state.email_group_id),
            interval_next_generation: parseInt(this.state.interval_next_generation),
            last_unixtimestamp_run: parseInt(this.state.last_unixtimestamp_run),
            next_unixtimestamp_run: parseInt(this.state.next_unixtimestamp_run),
            mode: parseInt(this.state.mode),
            enable: parseInt(this.state.enable),
            publication_auto: parseInt(this.state.publication_auto),
            pdf_result: parseInt(this.state.pdf_result),
            number_of_days: parseInt(this.state.number_of_days),
            json_config: JSON.stringify(json_config)
        }

        //Envoi la requête de création des rapports gabarits
        if (this.props !== undefined && this.props.preconfigurerRapportGabarit === undefined) {
            //si les rapports gabarits n'a pas de pré-remplissage des champs du formulaire
            this.props.reqCreerRapportGabarit(formulaireRapportGabarit);
        } else {
            //sinon modification
            formulaireRapportGabarit["template_report_id"] = parseInt(this.props.preconfigurerRapportGabarit.template_report_id);
            this.props.reqModifierRapportGabarit(formulaireRapportGabarit)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value});
    gererEtatDeCibleNameStylePolice = (event) => this.setState({
        ...this.state,
        graphical_charter: {
            ...this.state.graphical_charter,
            font_style: {...this.state.graphical_charter.font_style, [event.target.name]: event.target.value}
        }
    });
    gererArrayEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: IsArrayString(event.target.value)
    });
    gererProjetId = projetId => this.setState({...this.state, project_id: projetId});
    gererTimeZone = time_zone => this.setState({...this.state, time_zone: time_zone});
    affichageFormulaire = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.array:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGabarit !== undefined ? JSON.stringify(this.state[label_state]) : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererArrayEtatDeCibleName(event)}/>
                    </div>
                )
            default:
                return (
                    <div className={[style, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGabarit !== undefined ? this.state[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleName(event)}/>
                    </div>
                )
        }
    }
    affichageFormulaireStylePolice = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.array:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGabarit !== undefined ? JSON.stringify(this.state.graphical_charter.font_style[label_state]) : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleNameStylePolice(event)}/>
                    </div>
                )
            case glossaire_pattern.integer:
                return (
                    <div className={[style, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGabarit !== undefined ? this.state.graphical_charter.font_style[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleNameStylePolice(event)}/>
                    </div>
                )
            default:
                return (
                    <div className={[style, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGabarit !== undefined ? this.state.graphical_charter.font_style[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleNameStylePolice(event)}/>
                    </div>
                )
        }
    }
    gererLast_unixtimestamp_run = (unix) => this.setState({...this.state, last_unixtimestamp_run: unix});
    gererNext_unixtimestamp_run = (unix) => this.setState({...this.state, next_unixtimestamp_run: unix});
    gererPage_headerOmit_cover_page = (event) => this.setState({
        ...this.state,
        graphical_charter: {
            ...this.state.graphical_charter,
            page_header: {...this.state.graphical_charter.page_header, omit_cover_page: event.target.value}
        }
    });
    gererPage_footerOmit_cover_page = (event) => this.setState({
        ...this.state,
        graphical_charter: {
            ...this.state.graphical_charter,
            page_footer: {...this.state.graphical_charter.page_footer, omit_cover_page: event.target.value}
        }
    });
    gererCover_pageCover_centered = (event) => this.setState({
        ...this.state,
        cover_page: {...this.state.cover_page, cover_centered: parseInt(event.target.value)}
    });
    gererPage_headerObject_list = (objects_list) => this.setState({
        ...this.state,
        graphical_charter: {
            ...this.state.graphical_charter,
            page_header: {...this.state.graphical_charter.page_header, objects_list: [...objects_list]}
        }
    });
    gererPage_footerObject_list = (objects_list) => this.setState({
        ...this.state,
        graphical_charter: {
            ...this.state.graphical_charter,
            page_footer: {...this.state.graphical_charter.page_footer, objects_list: [...objects_list]}
        }
    });
    gererCover_pageObject_list = (objects_list) => this.setState({
        ...this.state,
        cover_page: {objects_list: [...objects_list]}
    });
    gererBody_pageObject_list = (objects_list) => this.setState({
        ...this.state,
        body_page: {objects_list: [...objects_list]}
    });
    gererEmailgroupId = (emailgroupId) => this.setState({...this.state, email_group_id: emailgroupId});
    gererIntervalNextGeneration = (interval_next_generation) => this.setState({
        ...this.state,
        interval_next_generation: parseInt(interval_next_generation)
    });

    render() {
        const barre_outil = this.props.affichageProfil === true ? null : <div className={s.form_group}>
            <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                <div>
                    <Bouton type={type_btn.danger} cliquer={(e) => {
                        e.preventDefault();
                        this.props.annuler();
                    }}>{checkMot("annuler")}</Bouton>
                </div>
                <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                    {this.props !== undefined && this.props.preconfigurerRapportGabarit === undefined
                        ?
                        <AttenteRequete request_type={requestType.creer_un_rapport_gabarit}
                                        str={checkMot("creation_en_cour")}>
                            <Bouton type={type_btn.succes} ajoutClass={S.Bts}
                                    cliquer={() => this.gererEnvoi()}><AiOutlineCheck/>
                                {checkMot("creer")}</Bouton>
                        </AttenteRequete>
                        : <AttenteRequete request_type={requestType.modifier_un_rapport_gabarit}
                                          str={checkMot("modification_en_cour")}>
                            <Bouton type={type_btn.succes}
                                    cliquer={() => this.gererEnvoi()}>{checkMot("enregistrer")}</Bouton>
                        </AttenteRequete>
                    }
                </div>
            </div>
        </div>
        return (
            <Auxiliaire>
                <form className={[S.formulaire, S.boxing].join(" ")} onSubmit={event => event.preventDefault()}>
                    <Collapse showAdvanceParameters={true} ExpandText={checkMot("info_general")}>
                        <div className={s.form_group}>
                            <div className={[s.form_row, s.text_left].join(" ")}>
                                {/**DEBUT project_id**/}
                                <FormulaireSelectionProjet
                                    affichageProfil={this.props.affichageProfil} gererProjetId={this.gererProjetId}
                                    preconfigurerProjetId={this.state.project_id}/>
                                {/**FIN project_id**/}
                                {/**DEBUT name**/}
                                {this.affichageFormulaire(s.col_12, "name", checkMot("name"), glossaire_pattern.text)}
                                {/**FIN name**/}
                                {/**DEBUT time_zone**/}

                                <div className={[s.col_12, S.style].join(" ")}>
                                    <FormulaireSelectionTimeZone
                                        affichageProfil={this.props.affichageProfil} gererTimeZone={this.gererTimeZone}
                                        preconfigurerTimeZone={this.state.time_zone}/>
                                </div>
                                {/**FIN time_zone**/}
                                {/**DEBUT last_unixtimestamp_run next_unixtimestamp_run**/}

                                <div className={[s.form_group, S.text_center].join(" ")}>
                                    <div className={[s.form_row, s.align_items_center].join(" ")}>
                                        <span
                                            className={[S.stil2, s.col_12].join(" ")}> {checkMot("derniere_generation")}</span>
                                        <div className={s.col_12}>
                                            {/**DEBUT last_unixtimestamp_run**/}<FormulaireSelectionDateHeureUnix
                                            value={this.state.last_unixtimestamp_run}
                                            gereUnixDate={this.gererLast_unixtimestamp_run}
                                            disabled={this.props.affichageProfil === true}/>
                                            {/**FIN last_unixtimestamp_run**/}
                                        </div>
                                        <span
                                            className={[S.stil2, s.col_12].join(" ")}> {checkMot("prochaine_generation")}</span>
                                        <div className={s.col_12}>
                                            {/**DEBUT next_unixtimestamp_run**/}<FormulaireSelectionDateHeureUnix
                                            value={this.state.next_unixtimestamp_run}
                                            gereUnixDate={this.gererNext_unixtimestamp_run}
                                            disabled={this.props.affichageProfil === true}/>{/**FIN next_unixtimestamp_run**/}
                                        </div>
                                    </div>
                                </div>
                                {/**FIN last_unixtimestamp_run next_unixtimestamp_run**/}
                                {/**DEBUT mode**/}
                                <div className={[s.col_6, S.style].join(" ")}>
                                    <span className={[S.stil2,].join(" ")}>{checkMot("mode")}</span>
                                    <select
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                        defaultValue={this.state.mode}
                                        disabled={this.props.affichageProfil === true}
                                        value={this.state.mode}
                                        name={"mode"}
                                        className={[s.custom_select].join(" ")}>
                                        <option value={0}>0: {checkMot("jour")}</option>
                                        <option value={1}>1: {checkMot("mensuel")}</option>
                                    </select>
                                </div>
                                {/**FIN mode**/}
                                {/**DEBUT enable**/}
                                <div className={[s.col_6, S.style].join(" ")}>
                                    <span className={[S.stil2,].join(" ")}>{checkMot("enable")}</span>
                                    <select
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                        defaultValue={this.state.enable}
                                        disabled={this.props.affichageProfil === true}
                                        value={this.state.enable}
                                        name={"enable"}
                                        className={[s.custom_select].join(" ")}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                    </select>
                                </div>
                                {/**FIN enable**/}
                                {/**DEBUT number_of_days**/}
                                {this.affichageFormulaire(s.col_6, "number_of_days", checkMot("number_of_days"), glossaire_pattern.integer)}
                                {/**FIN number_of_days**/}
                                {/**DEBUT email_group_id**/}
                                <div className={[s.col_6, S.style].join(" ")}>
                                    <FormulaireSelectionEmailgroup
                                        affichageProfil={this.props.affichageProfil}
                                        gererEmailgroupId={this.gererEmailgroupId}
                                        preconfigurerEmailgroupId={this.state.email_group_id}/>
                                </div>
                                {/**FIN email_group_id**/}
                                {/**DEBUT interval_next_generation**/}
                                {/*{this.affichageFormulaire(s.col_6, "interval_next_generation", checkMot("interval_next_generation"), glossaire_pattern.integer)}*/}
                                <div className={[s.col_6, S.style].join(" ")}>

                                    <span className={[S.stil2,].join(" ")}>{checkMot("interval_next_generation")}</span>
                                    <FormulaireSaisieDuree
                                        affichageProfil={this.props.affichageProfil}
                                        gererDuree={this.gererIntervalNextGeneration}
                                        preconfigurerDuree={this.state.interval_next_generation}
                                    />
                                </div>
                                {/**FIN interval_next_generation**/}
                                {/**DEBUT publication_auto**/}
                                <div className={[s.col_6, S.style].join(" ")}>
                                    <span className={[S.stil2,].join(" ")}>{checkMot("publication_auto")}</span>
                                    <select
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                        defaultValue={this.state.publication_auto}
                                        disabled={this.props.affichageProfil === true}
                                        value={this.state.publication_auto}
                                        name={"publication_auto"}
                                        className={[s.custom_select].join(" ")}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                    </select>
                                </div>
                                {/**FIN publication_auto**/}
                                {/**DEBUT pdf_result**/}
                                <div className={[s.col_6, S.style].join(" ")}>
                                    <span className={[S.stil2,].join(" ")}>{checkMot("pdf_result")}</span>
                                    <select
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                        defaultValue={this.state.pdf_result}
                                        disabled={this.props.affichageProfil === true}
                                        value={this.state.pdf_result}
                                        name={"pdf_result"}
                                        className={[s.custom_select].join(" ")}>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                    </select>
                                </div>
                                {/**FIN pdf_result**/}
                            </div>
                        </div>
                        {barre_outil}
                    </Collapse>
                </form>
                <form className={[S.formulaire, S.chart_graph].join(" ")} onSubmit={event => event.preventDefault()}>
                    <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                              ExpandText={checkMot("sommaire")}>
                        <span onClick={() => this.setState({show_summary: !this.state.show_summary})}>
                            <input checked={this.state.show_summary}
                                   disabled={this.props.affichageProfil === true}
                                   type={"checkbox"}/> {this.state.show_summary ? checkMot("afficher_le_sommaire") : checkMot("ne_pas_afficher_le_sommaire")}
                        </span>
                    </Collapse>
                    {
                        this.state.graphical_charter !== undefined
                            ? <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                        ExpandText={checkMot("charte_graphique")}>

                                <form className={[S.formulaire, S.haut_page].join(" ")}
                                      onSubmit={event => event.preventDefault()}>
                                    <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                              ExpandText={checkMot("haut_de_page")}>
                                        {/**DEBUT omit_cover_page**/}
                                        <div className={[s.col_12, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("premiere_page")}</span>

                                            <select
                                                onChange={(event) => this.gererPage_headerOmit_cover_page(event)}
                                                defaultValue={this.state.graphical_charter.page_header.omit_cover_page}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.graphical_charter.page_header.omit_cover_page}
                                                className={[s.custom_select].join(" ")}>
                                                <option value={1}>{checkMot("omettre_la_premiere_page")}</option>
                                                <option value={0}>{checkMot("afficher_sur_la_premiere_page")}</option>
                                            </select>
                                        </div>
                                        {/**FIN omit_cover_page**/}
                                        {barre_outil}

                                        <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                                  ExpandText={checkMot("objects_list")}>
                                            <FormulaireListesObjetsRapport project_id={this.state.project_id}

                                                                           affichageProfil={this.props.affichageProfil}
                                                                           gererObjectsList={this.gererPage_headerObject_list}
                                                                           objects_list={this.state.graphical_charter.page_header.objects_list}/>
                                        </Collapse>
                                    </Collapse>
                                </form>
                                <form className={[S.formulaire, S.bas_page].join(" ")}
                                      onSubmit={event => event.preventDefault()}>
                                    <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                              ExpandText={checkMot("bas_de_page")}>
                                        {/**DEBUT omit_cover_page**/}
                                        <div className={[s.col_12, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("premiere_page")}</span>
                                            <select
                                                onChange={(event) => this.gererPage_footerOmit_cover_page(event)}
                                                defaultValue={this.state.graphical_charter.page_footer.omit_cover_page}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.graphical_charter.page_footer.omit_cover_page}
                                                className={[s.custom_select].join(" ")}>
                                                <option value={1}>{checkMot("omettre_la_premiere_page")}</option>
                                                <option value={0}>{checkMot("afficher_sur_la_premiere_page")}</option>
                                            </select>
                                        </div>
                                        {/**FIN omit_cover_page**/}
                                        {barre_outil}

                                        <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                                  ExpandText={checkMot("objects_list")}>
                                            <FormulaireListesObjetsRapport project_id={this.state.project_id}

                                                                           affichageProfil={this.props.affichageProfil}
                                                                           gererObjectsList={this.gererPage_footerObject_list}
                                                                           objects_list={this.state.graphical_charter.page_footer.objects_list}/>
                                        </Collapse>
                                    </Collapse>
                                </form>
                                <form className={[S.formulaire, S.config].join(" ")}
                                      onSubmit={event => event.preventDefault()}>
                                    <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                              ExpandText={checkMot("style_de_police")}>
                                        {/**DEBUT family**/}
                                        <div className={[s.col_12, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("famille_de_police")}</span>
                                            <select
                                                onChange={(event) => this.gererEtatDeCibleNameStylePolice(event)}
                                                defaultValue={this.state.graphical_charter.font_style.family}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.graphical_charter.font_style.family}
                                                name={"family"}
                                                className={[s.custom_select].join(" ")}>
                                                <option value={"Verdana"}>{"Verdana"}</option>
                                                <option value={"Franklin book"}>{"Franklin book"}</option>
                                                <option value={"Montserrat"}>{"Montserrat"}</option>
                                                <option value={"Arial"}>{"Arial"}</option>
                                            </select>
                                        </div>
                                        {/**FIN family**/}
                                        {/**DEBUT style**/}
                                        <div className={[s.col_12, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("famille_de_police")}</span>
                                            <select
                                                onChange={(event) => this.gererEtatDeCibleNameStylePolice(event)}
                                                defaultValue={this.state.graphical_charter.font_style.style}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.graphical_charter.font_style.style}
                                                name={"style"}
                                                className={[s.custom_select].join(" ")}>
                                                <option value={"normal"}>{"normal"}</option>
                                                <option value={"italic"}>{"italic"}</option>
                                            </select>
                                        </div>
                                        {/**FIN style**/}
                                        {/**DEBUT weight**/}{this.affichageFormulaireStylePolice(s.col_12, "weight", checkMot("weight"), glossaire_pattern.integer)}{/**FIN weight**/}
                                        {/**DEBUT line_height**/}{this.affichageFormulaireStylePolice(s.col_12, "line_height", checkMot("line_height"), glossaire_pattern.text)}{/**FIN line_height**/}
                                        {/**DEBUT size**/}{this.affichageFormulaireStylePolice(s.col_12, "size", checkMot("size"), glossaire_pattern.integer)}{/**FIN size**/}
                                        {barre_outil}
                                    </Collapse>
                                </form>
                            </Collapse>


                            : this.setState({
                                ...this.state,
                                graphical_charter: {
                                    page_header: {omit_cover_page: 1, objects_list: []},
                                    page_footer: {omit_cover_page: 1, objects_list: []},
                                    font_style: {
                                        family: "Montserrat",
                                        style: "italic",
                                        weight: 2,
                                        line_height: "normal",
                                        size: 2
                                    },
                                },
                            })
                    }
                    {
                        this.state.cover_page !== undefined
                            ? <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                        ExpandText={checkMot("cover_page")}>
                                {/**DEBUT cover_centered**/}
                                <div className={[s.col_12, S.style].join(" ")}>
                                    <span className={[S.stil2,].join(" ")}>{checkMot("centrer_la_page_de_garde")}</span>
                                    <select
                                        onChange={(event) => this.gererCover_pageCover_centered(event)}
                                        defaultValue={this.state.cover_page.cover_centered}
                                        disabled={this.props.affichageProfil === true}
                                        value={this.state.cover_page.cover_centered}
                                        className={[s.custom_select].join(" ")}>
                                        <option value={1}>{checkMot("centrer_la_page_de_garde")}</option>
                                        <option value={0}>{checkMot("ne_pas_centrer_la_page_de_garde")}</option>
                                    </select>
                                </div>
                                {/**FIN cover_centered**/}
                                <FormulaireListesObjetsRapport project_id={this.state.project_id}
                                                               affichageProfil={this.props.affichageProfil}
                                                               gererObjectsList={this.gererCover_pageObject_list}
                                                               objects_list={this.state.cover_page.objects_list}/>
                            </Collapse>
                            : this.setState({...this.state, cover_page: {cover_centered: 1, objects_list: []}})
                    }
                    {
                        this.state.body_page !== undefined
                            ? <Collapse showAdvanceParameters={this.props.affichageProfil === false}
                                        ExpandText={checkMot("body_page")}>
                                <FormulaireListesObjetsRapport project_id={this.state.project_id}
                                                               affichageProfil={this.props.affichageProfil}
                                                               gererObjectsList={this.gererBody_pageObject_list}
                                                               objects_list={this.state.body_page.objects_list}/>
                            </Collapse>
                            : this.setState({...this.state, body_page: {objects_list: []}})
                    }
                    {barre_outil}
                </form>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reqCreerRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
        reqModifierRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireRapportGabarit);
