import React, { useState, useEffect } from 'react';
import { IoIosHelpCircleOutline } from "react-icons/io";
import S from './Bouton.module.scss';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";

const HoverButton = ({ imageUrl, customStyle, customClass, texte  }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [loadedImage, setLoadedImage] = useState(null);
  
    useEffect(() => {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => setLoadedImage(image);
    }, [imageUrl]);
  
    return (
        <span 
            
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={customStyle}
        >
            <IoIosHelpCircleOutline size={25} />
            {isHovering && texte && <div className={`${customClass}`}>{texte}</div>}
            {isHovering && loadedImage && (
                <Auxiliaire>
                    <img
                        src={loadedImage.src}
                        className={`${customClass}`}
                        alt=""
                    />
                </Auxiliaire>
            )}
        </span>
    );
};

export default HoverButton;
