import React, {Component} from "react";
import * as localeDictionary from "plotly.js/lib/locales/fr";
import Plot from "react-plotly.js";

class DiagrammeCirculaire extends Component {
    /**
     *
     * @return {JSX.Element}
     */
    state = {
        afficherRangeSlider: true,
        afficherModeBar: false,
    }

    render() {
        let values = [];
        let labels = [];

        console.log("this.props.donnees", this.props.donnees)
        for(let courbe of this.props.donnees){
            values.push(courbe.y.reduce((a, b) => a + b, 0))
            labels.push(courbe.prefix)
            // console.log("courbe", courbe)
        }
        let styleData = {}
        if (this.props.viewobj_json_content.styleData !== undefined){
            styleData = this.props.viewobj_json_content.styleData;
        }

        let data = [{
                type: "pie",
                values: values,
                labels: labels,
                textinfo: "label+percent",
                insidetextorientation: "radial",
            ...styleData
            }];
        return <Plot
            data={data}
            layout={{
                responsive: true,
                "width": this.props.dimensionWidth !== undefined ? this.props.dimensionWidth : window.innerHeight - 250, //150
                "height": this.props.dimensionHeight !== undefined ? this.props.dimensionHeight : window.innerWidth - 165, //165
                // // title: this.props.minimaliste === true ? "" : this.props.viewobj_json_content.layout.title,
                // legend: {
                //     x: 1.05,//todo rendre la légende responsive
                //     y: 1
                // },
                modebar: {
                    // vertical modebar button layout
                    orientation: 'h',
                    // for demonstration purposes
                    bgcolor: 'rgba(0, 0, 0, 0)',
                    color: 'black',
                    activecolor: '#eeb214'
                },
                margin: {r: 50},
                ...this.props.viewobj_json_content.layout,
            }}


            config={{
                locales: {'fr': localeDictionary},
                locale: 'fr',
                displayModeBar: this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? true : this.props.viewobj_json_content.length === 0 || this.props.viewobj_json_content.displayModeBar === true ? true : false,
                displaylogo: false,
                modeBarButtonsToRemove: [''],
            }}

        />;
    }
}

export default DiagrammeCirculaire;
