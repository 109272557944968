export const glossaire_couleurs = {
    "rougeITM": "#A6163F",
    "jauneSB": "#f0af00",
    "gris2SB": "#646464",
    "gris1SB": "#b4b4b4",
    "blancSB": "#ffffff",
    "noirSB": "#000000",
    "bleu3SB": "#074c7c",
    "bleu2SB": "#4b96cd",
    "bleu1SB": "#33b8c9",
    "vertSB": "#49B857",
    "orangeSB": "#ea690a",
    "violetSB": "#7570ad",
}


// checkMot("rouge_itm")
// checkMot("jaune_sb")
// checkMot("gris2_sb")
// checkMot("gris1_sb")
// checkMot("blanc_sb")
// checkMot("noir_sb")
// checkMot("bleu3_sb")
// checkMot("bleu2_sb")
// checkMot("bleu1_sb")
// checkMot("vert_sb")
// checkMot("orange_sb")
// checkMot("violet_sb")