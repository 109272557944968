import Plot from "react-plotly.js";
import React from "react";
import * as glossaire_type_de_graphe from "../../../../ressources/glossaires/glossaire_type_de_graphe";
import * as localeDictionary from 'plotly.js/lib/locales/fr.js'
import * as S from "./BibliothequePlotly.module.scss";
import {defaultColors} from "../../../UI/ColorPlotly"
import {checkMot} from "../../../../ressources/functionJS/checkMot";

function debounce(fn, ms) {

    //limite le nombre de calcul
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

let modifier_intervalle = {
    'width': 14,
    'height': 14,
    'path': 'M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z'
}
let Expor_CSV = {
    'width': 14,
    'height': 14,
    'path': 'M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z'
}
let RangeSlider = {
    'width': 15,
    'height': 15,
    'path': 'M6 13a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v10zM14.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5zm-13 0a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z'
}
let barMode = {
    'width': 15,
    'height': 15,
    'path': 'M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z'
}
let scatterMode = {
    'width': 15,
    'height': 15,
    'path': 'M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z'
}
let Y2 = {
    'width': 1000,
    'height': 1000,
    'path': 'M578.2,842.8L578.2,842.8c0-44.4,11-80.7,33.1-108.9c22.1-28.2,63.5-59,124.3-92.4c29.5-16.4,49.6-31.5,60.4-45.2c10.7-13.7,16.1-31.3,16.1-52.8c0-22.5-8-40.9-24-55.2c-16-14.3-36.5-21.4-61.5-21.4c-26.2,0-46.7,9-61.7,26.9c-14.9,17.9-22.9,43.3-23.9,76.3h-51.3c-0.8-46.5,11.6-83,37.2-109.5c25.6-26.5,61.2-39.7,107-39.7c39.1,0,71.1,11.6,96.1,34.9c24.8,23.1,37.2,52.8,37.2,89.4c0,31.1-8,56.6-23.9,76.6c-15.9,20-45.9,42.2-89.8,66.4c-47.7,26.2-80.2,50.1-97.6,71.8c-7.2,9.4-13.2,19.9-17.9,31.7h229.2v33C943.6,738.1,990,624.4,990,500c0-270.6-219.4-490-490-490C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c137.2,0,261.2-56.4,350.1-147.2L578.2,842.8L578.2,842.8z M427,611v244.4h-41.4h-41.8V609.8L112,241.3h48.4h48.8l176.3,298.2l0.2-0.2l176.7-298h48.1h48.1L427,611z'
}
let X2 = {
    'width': 42,
    'height': 42,
    'path': 'M36.5,6h-25C8.468,6,6,8.468,6,11.5v25c0,3.032,2.468,5.5,5.5,5.5h25c3.032,0,5.5-2.468,5.5-5.5v-25\tC42,8.468,39.532,6,36.5,6z M21.652,28.54c0.53,0.636,0.444,1.582-0.192,2.112C21.18,30.886,20.839,31,20.501,31\tc-0.43,0-0.856-0.184-1.153-0.54L18,28.843l-1.348,1.617C16.355,30.816,15.929,31,15.499,31c-0.338,0-0.679-0.114-0.959-0.348\tc-0.636-0.53-0.722-1.477-0.192-2.112l1.7-2.04l-1.7-2.04c-0.53-0.636-0.444-1.582,0.192-2.112c0.638-0.53,1.583-0.444,2.113,0.192\tL18,24.157l1.348-1.617c0.53-0.637,1.476-0.723,2.113-0.192c0.636,0.53,0.722,1.477,0.192,2.112l-1.7,2.04L21.652,28.54z M32.5,28\tc0.829,0,1.5,0.671,1.5,1.5S33.329,31,32.5,31h-7c-0.829,0-1.5-0.671-1.5-1.5c0-3.779,2.592-5.5,5-5.5c1.103,0,2-0.897,2-2\ts-0.897-2-2-2c-1.463,0-1.903,0.931-2.015,1.712c-0.117,0.821-0.878,1.391-1.697,1.273c-0.82-0.117-1.39-0.877-1.273-1.697\tC24.387,18.683,26.344,17,29,17c2.757,0,5,2.243,5,5s-2.243,5-5,5c-0.336,0-1.218,0.093-1.691,1H32.5z'
}
const BibliothequePlotly = (props) => {

    let outilPlotly = [
        {
            name: checkMot("modifier_intervalle"),
            icon: modifier_intervalle,
            direction: 'up',
            click: () => props.ouvrirPopupModifierIntervalle()
        },
        {
            name: checkMot("export__csv_des_donnees_du_graphe"),
            icon: Expor_CSV,
            direction: 'up',
            click: () => download_csv_file()
        },


    ]

    if (props.afficherRangeSlider instanceof Function) {
        outilPlotly.push(
            {
                name: checkMot("barre_de_zoom"),
                icon: RangeSlider,
                direction: 'up',
                click: props.afficherRangeSlider instanceof Function ? props.afficherRangeSlider : () => {
                }
            })
    }
    if (props.afficherModeBar instanceof Function) {
        if (props.modeBar === true) {
            outilPlotly.push(
                {
                    name: checkMot("mode_lineair"),
                    icon: scatterMode,
                    direction: 'up',
                    click: props.afficherModeBar instanceof Function ? props.afficherModeBar : () => {
                    }
                })
        } else {
            outilPlotly.push(
                {
                    name: checkMot("mode_histogramme"),
                    icon: barMode,
                    direction: 'up',
                    click: props.afficherModeBar instanceof Function ? props.afficherModeBar : () => {
                    }
                })
        }
    }
    if (props.afficherY2 instanceof Function) {
        outilPlotly.push(
            {
                name: checkMot("y2"),
                icon: Y2,
                direction: 'up',
                click: props.afficherY2 instanceof Function ? props.afficherY2 : () => {
                }
            })
    }
    if (props.afficherX2 instanceof Function) {
        outilPlotly.push(
            {
                name: checkMot("x2"),
                icon: X2,
                direction: 'up',
                click: props.afficherX2 instanceof Function ? props.afficherX2 : () => {
                }
            })
    }
    const emptyString = (str) => str === "" || str.length === 0;

    // converti uts en date pour la bibliothèque plotly
    const utsToDate = (uts) =>
        Intl.DateTimeFormat('en-GB', {year: "numeric"}).format(uts * 1000) + "-" +
        Intl.DateTimeFormat('en-GB', {month: "2-digit"}).format(uts * 1000) + "-" +
        Intl.DateTimeFormat('en-GB', {day: "2-digit"}).format(uts * 1000) + " " +
        Intl.DateTimeFormat('en-GB', {hour: "2-digit"}).format(uts * 1000) + ":" +
        Intl.DateTimeFormat('en-GB', {minute: "2-digit"}).format(uts * 1000) + ":" +
        Intl.DateTimeFormat('en-GB', {second: "2-digit"}).format(uts * 1000);

    function download_csv_file() {

        let csv = "";
        let hiddenElement = document.createElement('a');
        switch (props.donneesGraphe.config.graph_type_id) {
            case glossaire_type_de_graphe.graphe_automatique:
                //ajout des données des différentes courbes
                for (let indSetDonnees = 0; indSetDonnees < props.donneesGraphe.data.length; indSetDonnees++) {
                    //colonne nom du capteur
                    let name = (props.donneesGraphe.data[indSetDonnees].name !== ""
                            ? props.donneesGraphe.data[indSetDonnees].name
                            : props.donneesGraphe.data[indSetDonnees].sensor_name) + "[" + ((!emptyString(props.donneesGraphe.data[indSetDonnees].prefix))
                            ? props.donneesGraphe.data[indSetDonnees].prefix : props.donneesGraphe.data[indSetDonnees].position_in_sensor)
                        + "]";
                    //entête
                    csv += 'date;' + name + '\n';
                    for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                        //ajout des données
                        csv += utsToDate(props.donneesGraphe.data[indSetDonnees].x[indDonnee]) + ";" + props.donneesGraphe.data[indSetDonnees].y[indDonnee];
                        csv += "\n";
                    }
                    //ajout d'une ligne vide
                    csv += "\n";
                }

                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.sensor_name + "_" + props.donneesGraphe.config.prefix + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.graphe_x_t:

                //ajout des données des différentes courbes
                let table_par_uts = [["date"]];
                for (let indSetDonnees = 0; indSetDonnees < props.donneesGraphe.data.length; indSetDonnees++) {
                    // initialisation du nom de la courbe pour l'entête
                    let name = (props.donneesGraphe.data[indSetDonnees].name !== ""
                            ? props.donneesGraphe.data[indSetDonnees].name
                            : props.donneesGraphe.data[indSetDonnees].sensor_name) + "[" + ((!emptyString(props.donneesGraphe.data[indSetDonnees].prefix))
                            ? props.donneesGraphe.data[indSetDonnees].prefix : props.donneesGraphe.data[indSetDonnees].position_in_sensor)
                        + "]";
                    table_par_uts[0].push(name);//ajoute le nom de la courbe à l'entête

                    for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                        let addedFlag = false;//booléen pour le cas où l'uts n'es pas ajout dans la liste
                        const valeur_uts = props.donneesGraphe.data[indSetDonnees].x[indDonnee];// valeur de l'uts à ajouter
                        const valeur_y = props.donneesGraphe.data[indSetDonnees].y[indDonnee];// valeur en y à ajouter
                        if (table_par_uts.length > 2 && valeur_uts < table_par_uts[1][0]) {
                            //ajout de la valeur uts
                            table_par_uts.splice(1, 0, [valeur_uts]);
                            // ajout des null pour mettre la valeur dans la bonne colonne
                            for (let indAjout = 0; indAjout < indSetDonnees; indAjout++) {
                                table_par_uts[1].push(null);
                            }
                            //ajout de la valeur y
                            table_par_uts[1].push(valeur_y);
                            addedFlag = true;
                        } else {
                            //todo: tri par insersion avec recherche dicotomique
                            for (let indUts = 1; indUts < table_par_uts.length; indUts++) {
                                //cas où les dates correspondent
                                if (table_par_uts[indUts][0] === valeur_uts) {
                                    // ajout des null pour mettre la valeur dans la bonne colonne
                                    for (let indAjout = table_par_uts[indUts].length; indAjout < indSetDonnees + 1; indAjout++) {
                                        table_par_uts[indUts].push(null);
                                    }
                                    //ajout de la valeur y
                                    table_par_uts[indUts].push(valeur_y);
                                    addedFlag = true;
                                    break;
                                }
                                //cas où la date n'existe pas
                                else if (indUts + 1 < table_par_uts.length &&
                                    table_par_uts[indUts][0] < valeur_uts &&
                                    valeur_uts < table_par_uts[indUts + 1][0]) {
                                    // ajout des null pour mettre la valeur dans la bonne colonne
                                    table_par_uts.splice(indUts + 1, 0, [valeur_uts]);
                                    for (let indAjout = 0; indAjout < indSetDonnees; indAjout++) {
                                        table_par_uts[indUts + 1].push(null);
                                    }
                                    //ajout de la valeur y
                                    table_par_uts[indUts + 1].push(valeur_y);
                                    addedFlag = true;
                                    break;
                                }
                            }
                        }
                        if (!addedFlag) {
                            //ajout de la valeur uts
                            table_par_uts.push([valeur_uts]);
                            // ajout des null pour mettre la valeur dans la bonne colonne
                            for (let indAjout = 0; indAjout < indSetDonnees; indAjout++) {
                                table_par_uts[table_par_uts.length - 1].push(null);
                            }
                            //ajout de la valeur y
                            table_par_uts[table_par_uts.length - 1].push(valeur_y);
                        }
                    }
                }

                // boucle pour compléter les colonnes qui n'ont pas de null à la fin
                for (let indUts = 0; indUts < table_par_uts.length; indUts++) {
                    //cas où les liste sont plus petites que la liste d'entête
                    if (table_par_uts[indUts].length < table_par_uts[0].length) {
                        //garde en mémoire la taille de la liste à compléter
                        const longueur_table = table_par_uts[indUts].length;
                        // boucle pour ajouter les nulls manquants
                        for (let indAjout = 0; indAjout < table_par_uts[0].length - longueur_table; indAjout++) {
                            table_par_uts[indUts].push(null);
                        }
                    }
                }
                //recupération du timazone
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                //ajout de la zone du fuseau horaire dans l'entête de la colonne date
                table_par_uts[0][0] = table_par_uts[0][0] + " (" + timeZone + ")";
                //initialisation du csv avec l'entête
                let csv_export = table_par_uts[0].join(";") + "\n";
                //boucle pour ajouter chaque ligne de données
                for (let indUts = 1; indUts < table_par_uts.length; indUts++) {
                    //convertis l'uts en date lisible
                    table_par_uts[indUts][0] = utsToDate(table_par_uts[indUts][0]);
                    //concatène chaque valeur de la liste avec ";" et ajout d'un saut de ligne
                    csv_export += table_par_uts[indUts].join(";") + "\n";
                }

                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv_export);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.graph_name + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.graphe_x_y:
                //ajout des données des différentes courbes
                for (let indSetDonnees = 0; indSetDonnees < props.donneesGraphe.data.length; indSetDonnees++) {
                    //colonne nom de la donnée en x
                    let nameX = props.donneesGraphe.config.xaxis.title;

                    //colonne nom de la donnée en y
                    let nameY;
                    //mise en forme de l'information de l'unité de mesure
                    if (props.donneesGraphe.data[indSetDonnees].yaxis === "y1") {
                        nameY = props.donneesGraphe.config.yaxis.title;
                    } else if (props.donneesGraphe.data[indSetDonnees].yaxis === "y2") {
                        nameY = props.donneesGraphe.config.yaxis2.title;
                    }
                    //titre du jeu de données
                    csv += props.donneesGraphe.data[indSetDonnees].name + '\n';
                    //entête
                    csv += 'date;' + nameX + ';' + nameY + '\n';
                    if (props.donneesGraphe.data[indSetDonnees].t.length === 1) {
                        for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                            //ajout des données
                            csv += utsToDate(props.donneesGraphe.data[indSetDonnees].t[0]) + ";" + props.donneesGraphe.data[indSetDonnees].x[indDonnee] + ";" + props.donneesGraphe.data[indSetDonnees].y[indDonnee];
                            csv += "\n";
                        }
                    } else {
                        for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                            //ajout des données
                            csv += utsToDate(props.donneesGraphe.data[indSetDonnees].t[indDonnee]) + ";" + props.donneesGraphe.data[indSetDonnees].x[indDonnee] + ";" + props.donneesGraphe.data[indSetDonnees].y[indDonnee];
                            csv += "\n";
                        }
                    }
                    //ajout d'une ligne vide entre les différents tables de données
                    csv += "\n";
                }

                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.graph_name + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.graphe_x_y_t:
                //ajout des données des différentes courbes
                for (let indSetDonnees = 0; indSetDonnees < props.donneesGraphe.data.length; indSetDonnees++) {
                    //colonne nom de la donnée en x
                    let nameX = props.donneesGraphe.config.xaxis.title;

                    //colonne nom de la donnée en y
                    let nameY;
                    //mise en forme de l'information de l'unité de mesure
                    if (props.donneesGraphe.data[indSetDonnees].yaxis === "y1") {
                        nameY = props.donneesGraphe.config.yaxis.title;
                    } else if (props.donneesGraphe.data[indSetDonnees].yaxis === "y2") {
                        nameY = props.donneesGraphe.config.yaxis2.title;
                    }
                    //titre du jeu de données
                    csv += props.donneesGraphe.data[indSetDonnees].name + '\n';
                    //entête
                    csv += 'date;' + nameX + ';' + nameY + '\n';
                    if (props.donneesGraphe.data[indSetDonnees].t.length === 1) {
                        for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                            //ajout des données
                            csv += utsToDate(props.donneesGraphe.data[indSetDonnees].t[0]) + ";" + props.donneesGraphe.data[indSetDonnees].x[indDonnee] + ";" + props.donneesGraphe.data[indSetDonnees].y[indDonnee];
                            csv += "\n";
                        }
                    } else {
                        for (let indDonnee = 0; indDonnee < props.donneesGraphe.data[indSetDonnees].x.length; indDonnee++) {
                            //ajout des données
                            csv += utsToDate(props.donneesGraphe.data[indSetDonnees].t[indDonnee]) + ";" + props.donneesGraphe.data[indSetDonnees].x[indDonnee] + ";" + props.donneesGraphe.data[indSetDonnees].y[indDonnee];
                            csv += "\n";
                        }
                    }
                    //ajout d'une ligne vide entre les différents tables de données
                    csv += "\n";
                }

                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.graph_name + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_rapport:
                let celulasRapport = []
                celulasRapport = props.data.map(obj => obj.cells.values);
                let enteteRapport = []
                enteteRapport = props.data.map(obj => obj.header.values);


                // Boucle à travers les éléments de l'entête (header)
                for (let indDonnee = 0; indDonnee < enteteRapport.length; indDonnee++) {
                    // Récupérer la ligne d'en-tête actuelle
                    let rowhead = enteteRapport[indDonnee];
                    // Ajouter les valeurs de la ligne d'en-tête à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                    csv += rowhead.join(";") + '\n';
                }

                // Boucle à travers les éléments des cellules
                for (let indDonnee = 0; indDonnee < celulasRapport.length; indDonnee++) {
                    // Récupérer la ligne actuelle
                    let row = celulasRapport[indDonnee];
                    // Convertir la première cellule de la ligne en chaîne
                    let cellsString = row[0].toString();
                    // Diviser la chaîne en une liste de cellules en utilisant la virgule comme séparateur
                    let cells = cellsString.split(',');
                    // Boucle à travers les cellules divisées
                    for (let i = 0; i < cells.length; i++) {
                        let row_array = []
                        // Ajouter les valeurs des cellules divisées à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                        for (let cell_row of row) {
                            row_array.push(cell_row[i]);
                        }
                        csv += row_array.join(";") + '\n';
                    }
                }
                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.title + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux_rapport:
                let celulasRapportBorn = []
                celulasRapportBorn = props.data.map(obj => obj.cells.values);
                let enteteRapportBorn = []
                enteteRapportBorn = props.data.map(obj => obj.header.values);

                // Boucle à travers les éléments de l'entête (header)
                for (let indDonnee = 0; indDonnee < enteteRapportBorn.length; indDonnee++) {
                    // Récupérer la ligne d'en-tête actuelle
                    let rowhead = enteteRapportBorn[indDonnee];
                    // Ajouter les valeurs de la ligne d'en-tête à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                    csv += rowhead.join(";") + '\n';
                }

                // Boucle à travers les éléments des cellules
                for (let indDonnee = 0; indDonnee < celulasRapportBorn.length; indDonnee++) {
                    // Récupérer la ligne actuelle
                    let row = celulasRapportBorn[indDonnee];
                    // Convertir la première cellule de la ligne en chaîne
                    let cellsString = row[0].toString();
                    // Diviser la chaîne en une liste de cellules en utilisant la virgule comme séparateur
                    let cells = cellsString.split(',');
                    // Boucle à travers les cellules divisées
                    for (let i = 0; i < cells.length; i++) {
                        let row_array = []
                        // Ajouter les valeurs des cellules divisées à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                        for (let cell_row of row) {
                            row_array.push(cell_row[i]);
                        }
                        csv += row_array.join(";") + '\n';
                    }
                }


                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.title + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux:
                let celulas = []
                celulas = props.data.map(obj => obj.cells.values);
                let entete = []
                entete = props.data.map(obj => obj.header.values);

                // Boucle à travers les éléments de l'entête (header)
                for (let indDonnee = 0; indDonnee < entete.length; indDonnee++) {
                    // Récupérer la ligne d'en-tête actuelle
                    let rowhead = entete[indDonnee];
                    // Ajouter les valeurs de la ligne d'en-tête à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                    csv += rowhead.join(";") + '\n';
                }

                // Boucle à travers les éléments des cellules
                for (let indDonnee = 0; indDonnee < celulas.length; indDonnee++) {
                    // Récupérer la ligne actuelle
                    let row = celulas[indDonnee];
                    // Convertir la première cellule de la ligne en chaîne
                    let cellsString = row[0].toString();
                    // Diviser la chaîne en une liste de cellules en utilisant la virgule comme séparateur
                    let cells = cellsString.split(',');
                    // Boucle à travers les cellules divisées
                    for (let i = 0; i < cells.length; i++) {
                        let row_array = []
                        // Ajouter les valeurs des cellules divisées à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                        for (let cell_row of row) {
                            row_array.push(cell_row[i]);
                        }
                        csv += row_array.join(";") + '\n';
                    }
                }


                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.title + '.csv';
                hiddenElement.click();
                break;
            case glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_responsable:
                let celulas12 = []
                celulas12 = props.data.map(obj => obj.cells.values);
                let entete12 = []
                entete12 = props.data.map(obj => obj.header.values);


                // Boucle à travers les éléments de l'entête (header)
                for (let indDonnee = 0; indDonnee < entete12.length; indDonnee++) {
                    // Récupérer la ligne d'en-tête actuelle
                    let rowhead = entete12[indDonnee];
                    // Ajouter les valeurs de la ligne d'en-tête à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                    csv += rowhead.join(";") + '\n';
                }

                // Boucle à travers les éléments des cellules
                for (let indDonnee = 0; indDonnee < celulas12.length; indDonnee++) {
                    // Récupérer la ligne actuelle
                    let row = celulas12[indDonnee];
                    // Convertir la première cellule de la ligne en chaîne
                    let cellsString = row[0].toString();
                    // Diviser la chaîne en une liste de cellules en utilisant la virgule comme séparateur
                    let cells = cellsString.split(',');
                    // Boucle à travers les cellules divisées
                    for (let i = 0; i < cells.length; i++) {
                        let row_array = []
                        // Ajouter les valeurs des cellules divisées à la variable csv en utilisant des points-virgules comme séparateur entre les cellules et un retour à la ligne pour séparer les lignes
                        for (let cell_row of row) {
                            row_array.push(cell_row[i]);
                        }
                        csv += row_array.join(";") + '\n';
                    }
                }
                //mis en place du fichier csv
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';

                //provide the name for the CSV file to be downloaded
                hiddenElement.download = props.donneesGraphe.config.title + '.csv';
                hiddenElement.click();
                break;
            default:
                break;
        }
    }


    // }


    //dimensions du graphe
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    React.useEffect(() => {
        //redimension du graphe selon la taille de la fenêtre
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 10)//interval de temps entre chaque calcul de redimension

        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })
    let afficheBarOutil;
    if (props.viewobj_json_content !== undefined && props.viewobj_json_content !== null) {
        afficheBarOutil = {
            displayModeBar: (props.viewobj_json_content.displayModeBar === undefined || props.viewobj_json_content.displayModeBar === true)
        }
    } else {
        afficheBarOutil = {}
    }
    let data = [...props.data.map(value => {
        if (value.labels !== undefined) {
            return {
                ...value, labels: value.labels.map(text => text + "      ")
            }
        }else{
            return value
        }
    })]
    return <div>
        <div className={S.msg}>{checkMot("une_resolution_plus_grande_est_requise_pour_acceder_au_graphe")}</div>
        <div className={S.graphMain}>
            <Plot

                data={Boolean(props.sensor_name_sort)
                    ? data.sort((a, b) => { //tri les données de data dans l'ordre alphabétique (visible dans la légende)
                        let fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
                        if (fa < fb) return -1;
                        if (fa > fb) return 1;
                        return 0;
                    })
                    : data
                }
                layout={{
                    responsive: true,
                    "width": props.dimensionWidth !== undefined ? props.dimensionWidth : dimensions.width - 300, //150
                    "height": props.dimensionHeight !== undefined ? props.dimensionHeight : dimensions.height - 165, //165
                    title: props.minimaliste === true ? "" : props.layout.title,
                    modebar: {
                        // vertical modebar button layout
                        orientation: 'h',
                        // for demonstration purposes
                        bgcolor: 'rgba(0, 0, 0, 0)',
                        color: 'black',
                        activecolor: '#eeb214'
                    },
                    margin: {r: 50},
                    legend: {

                        x: 1, //1.1
                        y: 1  //1
                    },
                    colorway: defaultColors,

                    ...props.layout,
                }}


                config={{
                    locales: {'fr': localeDictionary},
                    locale: 'fr',

                    ...afficheBarOutil,
                    displaylogo: false,
                    modeBarButtonsToAdd: [
                        ...outilPlotly
                    ],
                    modeBarButtonsToRemove: [''],
                }}

                onClick={props.onClick instanceof Function ? props.onClick : undefined}
                // onPurge={
                //     (figure, graphDiv) => {
                //         console.log("onPurge, temps uts", Date.now())
                //     }
                // }
                // onUpdate={
                //     (figure, graphDiv) => {
                //         console.log("onUpdate, temps uts", Date.now(), graphDiv)
                //         //todo: problème de trop d'update à l'initialisation des graphes sur une vue
                //     }
                // }
            />
        </div>
    </div>
}

export default BibliothequePlotly;
