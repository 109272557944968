import React, {Component} from "react";

import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";

import * as glossaire_type_de_graphe from "../../../../ressources/glossaires/glossaire_type_de_graphe";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {emptyString} from "../../../../ressources/functionJS/emptyString";

export const convertiUtsToDate = (arrayOfUts) => {
    return arrayOfUts.map(unix => {
            if (isNaN(unix)) {
                return null
            }
            let unixToconvert = Intl.DateTimeFormat('en-GB', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            }).format(unix * 1000)
            unixToconvert = unixToconvert.replaceAll("/", "-").replace(",", "")
            const time = unixToconvert.split(" ")[1]
            let splitDate = unixToconvert.split(" ")[0].split("-")
            let date = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
            return date + " " + time
        }
    )
}
export const formatTextUniteArray = (valeurs, format) => {
    let calculValeurs = [...formatUniteArray(valeurs, format, true)];
    let res = []
    let newFormat = format;
    if (format.split("2").length > 1) {
        newFormat = format.split("2")[1]
    }
    // boucle pour convertir chaque valeur dans le format en entrée
    for (let i = 0; i < calculValeurs.length; i++) {
        res.push(formatUnite(calculValeurs[i], format) + " " + newFormat)
    }
    return res
}

export const conversions = {
    // Composés
    "jj:hh:mm:ss": val => val / (24 * 60 * 60),
    "hh:mm:ss": val => val / (60 * 60),
    "mm:ss": val => val / 60,
    "mm2hh:mm": val => val / 60,
    "ss2hh:mm:ss": val => val / (60 * 60),
    "ss2mm:ss": val => val / 60,

    // Simples
    "jj": val => (val / (24 * 60 * 60)).toFixed(2),
    "hh": val => (val / (60 * 60)).toFixed(2),
    // "mm": val => (val / 60).toFixed(2), // todo: attention confusion avec millimètre
    "ss": val => val,

    // Jour vers
    "jj2hh": val => (val * 24).toFixed(2),
    "jj2mm": val => (val * 24 * 60).toFixed(2),
    "jj2ss": val => (val * 24 * 60 * 60).toFixed(2),

    // Heure vers
    "hh2jj": val => (val / 24).toFixed(2),
    "hh2mm": val => (val * 60).toFixed(2),
    "hh2ss": val => (val * 60 * 60).toFixed(2),

    // Minute vers
    "mm2jj": val => (val / (24 * 60)).toFixed(2),
    "mm2hh": val => (val / 60).toFixed(2),
    "mm2ss": val => (val * 60).toFixed(2),

    // Seconde vers
    "ss2jj": val => (val / (24 * 60 * 60)).toFixed(2),
    "ss2hh": val => (val / (60 * 60)).toFixed(2),
    "ss2mm": val => (val / 60).toFixed(2)
};

export const conversionsAffichageTexte = {
    // Composés
    "jj:hh:mm:ss": val => `${Math.floor(val / (24 * 60 * 60))}:${Math.floor((val % (24 * 60 * 60)) / (60 * 60))}:${Math.floor((val % (60 * 60)) / 60)}:${val % 60}`,
    "hh:mm:ss": val => `${Math.floor(val / (60 * 60))}:${Math.floor((val % (60 * 60)) / 60)}:${val % 60}`,
    "mm:ss": val => `${Math.floor(val / 60)}:${val % 60}`,
    "mm2hh:mm": val => `${Math.floor(val / 60)}:${val % 60}`,
    "ss2hh:mm:ss": val => `${Math.floor(val / (60 * 60))}:${Math.floor((val % (60 * 60)) / 60)}:${val % 60}`,
    "ss2mm:ss": val => `${Math.floor(val / 60)}:${val % 60}`,

    // Simples
    "jj": val => (val / (24 * 60 * 60)).toFixed(2),
    "hh": val => (val / (60 * 60)).toFixed(2),
    // "mm": val => (val / 60).toFixed(2), // todo: attention confusion avec millimètre
    "ss": val => val,

    // Jour vers
    "jj2hh": val => (val * 24).toFixed(2),
    "jj2mm": val => (val * 24 * 60).toFixed(2),
    "jj2ss": val => (val * 24 * 60 * 60).toFixed(2),

    // Heure vers
    "hh2jj": val => (val / 24).toFixed(2),
    "hh2mm": val => (val * 60).toFixed(2),
    "hh2ss": val => (val * 60 * 60).toFixed(2),

    // Minute vers
    "mm2jj": val => (val / (24 * 60)).toFixed(2),
    "mm2hh": val => (val / 60).toFixed(2),
    "mm2ss": val => (val * 60).toFixed(2),

    // Seconde vers
    "ss2jj": val => (val / (24 * 60 * 60)).toFixed(2),
    "ss2hh": val => (val / (60 * 60)).toFixed(2),
    "ss2mm": val => (val / 60).toFixed(2)
};

export const formatUnite = (valeur, format) => {
    if (Number.isInteger(valeur) && conversionsAffichageTexte.hasOwnProperty(format)) {
        return conversionsAffichageTexte[format](valeur);
    } else {
        return valeur;
    }
};

export const formatUniteArray = (valeurs, format, affichageText) => {
    let res = [];

    let formatAxe = format;
    if (format.split("2").length > 1 && affichageText) {
        formatAxe = format.split("2")[0];
    }
    // vérifie si le format est valide
    const isValidFormat = formatAxe in conversions;

    // boucle pour convertir chaque valeur dans le format en entrée
    for (let i = 0; i < valeurs.length; i++) {
        let calculValeur = valeurs[i];
        if (Number.isInteger(calculValeur) && isValidFormat) {
            res.push(conversions[formatAxe](calculValeur));
        } else {
            res.push(calculValeur);
        }
    }
    return res;
};

class GrapheXT extends Component {
    /**
     *
     * @return {JSX.Element}
     */
    state = {
        afficherRangeSlider: false,
        afficherManuelMode: false,
        afficherModeBar: false,
        afficherY2: false
    }

    render() {
        let layout = {};
        let data = [];
        let have_y2_curves = false;
        if (this.props.configuration !== undefined && this.props.donnees !== undefined) {
            layout = {
                font: {size: 14},
                title: {
                    text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle,
                    font: {
                        // size: 25,
                        color: '#3c3935',
                    },
                },
                barmode: this.props.configuration.barmode,
                xaxis: {
                    ...this.props.configuration.xaxis,
                    range: this.props.configuration.graph_type_id === glossaire_type_de_graphe.graphe_x_t && this.props.configuration.xaxis.range !== undefined && this.props.configuration.xaxis.range.length > 1 && this.props.configuration.xaxis.range[0] !== null && this.props.configuration.xaxis.range[1] !== null
                        ? convertiUtsToDate(this.props.configuration.xaxis.range)
                        : this.props.configuration.xaxis.range,
                    autorange: this.props.configuration.xaxis.range === undefined || this.props.configuration.xaxis.range.length === 0 || this.props.configuration.xaxis.range[0] === null || this.props.configuration.xaxis.range[1] === null,
                    showgrid: this.props.configuration.showgrid,
                    autoscale: !jsDictionaryEquals(this.props.configuration.xaxis.range, [null, null]),
                    rangeslider: this.props.viewobj_json_content === undefined && this.props.viewobj_json_content !== null ? {visible: this.state.afficherRangeSlider} : this.props.viewobj_json_content !== null && this.props.viewobj_json_content.showRangeslider === true ? {visible: this.state.afficherRangeSlider} : undefined

                },
                yaxis: {
                    ...this.props.configuration.yaxis,
                    showgrid: this.props.configuration.showgrid,
                    autoscale: !jsDictionaryEquals(this.props.configuration.yaxis.range, [null, null])
                },

                yaxis2: {
                    ...this.props.configuration.yaxis2,
                    showgrid: this.props.configuration.showgrid,
                    autoscale: this.props.configuration.yaxis2 !== undefined && !jsDictionaryEquals(this.props.configuration.yaxis2.range, [null, null]),
                    "overlaying": 'y',
                    side: 'right'
                },
                ...(this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? {} : this.props.viewobj_json_content.layout)

            };

            //mise en forme des données de composantes de capteurs
            for (let indSetDonnees = 0; indSetDonnees < this.props.donnees.length; indSetDonnees++) {
                if (this.props.donnees[indSetDonnees]["yaxis"] === 'y2') {
                    have_y2_curves = true
                }

                let color_curve = {};
                if (this.props.donnees[indSetDonnees]["color"] !== undefined) {
                    color_curve = {
                        marker: {
                            color: this.props.donnees[indSetDonnees]["color"]
                        },
                        line: {
                            color: this.props.donnees[indSetDonnees]["color"]
                        }
                    };
                }
                let text = [];
                if (Object.keys(conversionsAffichageTexte).indexOf(this.props.donnees[indSetDonnees].engineering_unit > -1)) {
                    text = [...formatTextUniteArray(this.props.donnees[indSetDonnees].y, this.props.donnees[indSetDonnees].engineering_unit)]
                } else {
                    for (let indDonnee = 0; indDonnee < this.props.donnees[indSetDonnees].x.length; indDonnee++) {

                        // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
                        const name = this.props.donnees[indSetDonnees].sensor_name
                        const valeurY = (!emptyString(this.props.donnees[indSetDonnees].name)
                                ? this.props.donnees[indSetDonnees].name
                                : (!emptyString(this.props.donnees[indSetDonnees].prefix)
                                    ? this.props.donnees[indSetDonnees].prefix
                                    : "[" + this.props.donnees[indSetDonnees].position_in_sensor + "]"))
                            + ": " + this.props.donnees[indSetDonnees].y[indDonnee] + " " + this.props.donnees[indSetDonnees].engineering_unit;
                        // const valeurX = layout.xaxis.title+": "+utsToDate(this.props.donnees[indSetDonnees].x[indDonnee]);

                        // mise en forme de l'info bulle pour chaque donnée
                        let infoBulle = [
                            name,
                            valeurY,
                            // valeurX,
                        ];
                        text.push(infoBulle.join("<br>"));
                    }
                }
                //converti l'axe des uts en date format plotly (exemple: "2013-10-04 22:23:00")
                const timeAxis = convertiUtsToDate(this.props.donnees[indSetDonnees].x);
                console.log([...this.props.donnees[indSetDonnees].y])
                const valeurAxis = formatUniteArray(this.props.donnees[indSetDonnees].y, this.props.donnees[indSetDonnees].engineering_unit);

                let courbe = {
                    ...this.props.donnees[indSetDonnees],
                    x: timeAxis,
                    y: valeurAxis,
                    text: text,
                    hovertemplate: '%{text}<extra></extra>',
                    ...color_curve
                };

                //affiche le nom du capteur [prefix || num composante] si name ===""
                if (this.props.donnees[indSetDonnees].name === "") {
                    courbe.name = this.props.donnees[indSetDonnees].sensor_name + "[" + (this.props.donnees[indSetDonnees].prefix !== "" ? this.props.donnees[indSetDonnees].prefix : this.props.donnees[indSetDonnees].position_in_sensor) + "]";
                }


                if (this.props.donnees[indSetDonnees].type === "bar") {
                    courbe.type = "bar"
                } else {
                    courbe.type = "scatter"
                }

                //cas où on utilise la bar d'outil pour changer le type d'affichage
                if (this.state.afficherManuelMode === true) {
                    if (this.state.afficherModeBar === true) {
                        courbe.type = "bar"
                    } else {
                        courbe.type = "scatter"
                    }
                }

                data.push(courbe);
            }

            //mise en forme des données des seuils de composantes
            for (let indSetSeuils = 0; indSetSeuils < this.props.seuils.length; indSetSeuils++) {
                //spread des config générales du groupes de seuils sur la liste des seuils
                if (this.props.seuils[indSetSeuils].show_alarms === undefined || parseInt(this.props.seuils[indSetSeuils].show_alarms) === 1) {
                    let afficherDansLegende = true
                    for (let indSeuil = 0; indSeuil < this.props.seuils[indSetSeuils].data.length; indSeuil++) {
                        if (!(jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].x, null)
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].x, [null])
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].x, [null, null])
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].x, undefined)
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].x, "")
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].y, null)
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].y, [null])
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].y, [null, null])
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].y, undefined)
                            || jsDictionaryEquals(this.props.seuils[indSetSeuils].data[indSeuil].y, "")
                        )) {
                            let text = [];
                            // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
                            const sensorName = "[" + checkMot("alarme") + "]" + !emptyString(this.props.seuils[indSetSeuils].name)
                                ? this.props.seuils[indSetSeuils].name
                                : this.props.seuils[indSetSeuils].sensor_name;
                            if (this.props.seuils[indSetSeuils].data[indSeuil].y !== undefined) {
                                for (let indValeur = 0; indValeur < this.props.seuils[indSetSeuils].data[indSeuil].y.length; indValeur++) {
                                    const seuil = (!emptyString(this.props.seuils[indSetSeuils].prefix)
                                            ? this.props.seuils[indSetSeuils].prefix
                                            : "[" + this.props.seuils[indSetSeuils].position_in_sensor + "]")
                                        + ": " + this.props.seuils[indSetSeuils].data[indSeuil].y[indValeur] + " " + this.props.seuils[indSetSeuils].engineering_unit;
                                    // mise en forme de l'info bulle pour chaque donnée
                                    let infoBulle = [
                                        sensorName,
                                        seuil,
                                    ];
                                    text.push(infoBulle.join("<br>"));
                                }
                            }
                            //distribue la configuration générale de la courbe de seuils dans les données
                            let courbe = {
                                ...this.props.seuils[indSetSeuils],
                                name: this.props.seuils[indSetSeuils].sensor_name,
                                ...this.props.seuils[indSetSeuils].data[indSeuil],
                                x: convertiUtsToDate(this.props.seuils[indSetSeuils].data[indSeuil].x),
                                y: formatUniteArray(this.props.seuils[indSetSeuils].data[indSeuil].y, this.props.seuils[indSetSeuils].data[indSeuil].engineering_unit),
                            };
                            let linecolor;
                            switch (courbe.line_color.startsWith("High ") || courbe.line_color.startsWith("Low ") ? courbe.line_color.split(" ")[1].toLowerCase() : courbe.line_color) {
                                case "black":
                                    linecolor = "black";
                                    break;
                                case "red":
                                    linecolor = "#f70606";
                                    break;
                                case "amber":
                                    linecolor = "#ffa233";
                                    break;
                                case "blue":
                                    linecolor = "#3390ff";
                                    break;
                                default:
                                    linecolor = "green";
                                    break;
                            }
                            data.push({
                                ...courbe,
                                type: "scatter",
                                line: {
                                    ...courbe.line,
                                    color: linecolor,
                                    dash: 'solid'
                                },
                                name: "[" + checkMot("alarme") + "] " + this.props.seuils[indSetSeuils].sensor_name,
                                legendgroup: this.props.seuils[indSetSeuils].sensor_name,
                                showlegend: afficherDansLegende,
                                text: text,
                                hovertemplate: '%{text}<extra></extra>',
                            });
                            afficherDansLegende = false //affiche que la première légende groupé
                        }
                    }
                }
            }
        }

        if (this.state.afficherY2 === true && have_y2_curves === false) {
            layout["legend"] = {
                x: 1.17,
                xanchor: 'right',
                y: 1
            }
            layout["yaxis"] = {
                ...layout["yaxis"],
                rangemode: 'tozero'
            }
            layout["yaxis2"] = {
                ...layout["yaxis"],
                title: '',
                titlefont: {color: 'rgb(166,22,63)'},
                tickfont: {color: 'rgb(166,22,63)'},
                overlaying: 'y',
                side: 'right',
                showlegend: false,//todo: hide legende
                gridcolor: "rgba(166,22,63,0.3)",
                gridwidth: 1,
                zerolinecolor: "rgba(166,22,63,0.7)",
                zerolinewidth: 2,
                hovermode: false,
            }
            let seuils_list = []
            for (let seuils of this.props.seuils) {//récupère la liste des seuils pour trouver les extremums
                for (let seuil_couleur of seuils.data) {
                    seuils_list.push(...seuil_couleur.y)
                }
            }
            // console.log(...this.props.seuils.map(seuils=>seuils.data.map(seuil => seuil.data.map(seuil_couleur => seuil_couleur.y))))
            data.push({//todo: hide text bulle
                x: [data[0].x[parseInt(data[0].x.length / 2)], data[0].x[parseInt(data[0].x.length / 2) + 1]],
                y: [Math.min(...data[0].y, ...seuils_list), Math.max(...data[0].y, ...seuils_list)],
                name: '',
                showlegend: false,
                yaxis: 'y2',
                type: data[0].type,
                mode: "none",
                hoverinfo: "none",
                opacity: 0
            })
        }
        //booleen pour trier la légende dans l'ordre alphabétique
        const sensor_name_sort = false;
        console.log(data)
        // console.log(this.props.donneesGraphe)
        return (
            <BibliothequePlotly
                sensor_name_sort={sensor_name_sort}
                data={data}
                layout={layout}
                ouvrirPopupModifierIntervalle={this.props.switchAfficherModifierIntervalle}
                donneesGraphe={this.props.donneesGraphe}
                dimensionWidth={this.props.dimensionWidth}
                dimensionHeight={this.props.dimensionHeight}
                minimaliste={this.props.minimaliste}
                viewobj_json_content={this.props.viewobj_json_content}
                afficherRangeSlider={() => this.setState({afficherRangeSlider: !this.state.afficherRangeSlider})}
                afficherModeBar={() => this.setState({
                    afficherManuelMode: true,
                    afficherModeBar: !this.state.afficherModeBar
                })}
                afficherY2={have_y2_curves ? null : () => this.setState({afficherY2: !this.state.afficherY2})}
                modeBar={this.state.afficherModeBar}
            />
        );
    }
}

export default GrapheXT;
