import React, {Component} from "react";
import * as localeDictionary from "plotly.js/lib/locales/fr";
import Plot from "react-plotly.js";
import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";

class DiagrammeSankey extends Component {
    /**
     *
     * @return {JSX.Element}
     */
    state = {
        afficherRangeSlider: true,
        afficherModeBar: false,
    }

    render() {
        let jsonLayout = {}
        let styleData = {}
        if (this.props.viewobj_json_content !== undefined) {
            if (this.props.viewobj_json_content.layout !== undefined) {
                jsonLayout = this.props.viewobj_json_content.layout;
            }
            if (this.props.viewobj_json_content.styleData !== undefined) {
                styleData = this.props.viewobj_json_content.styleData;
            }
        }

        let values = [];

        for (let courbe of this.props.donnees) {
            if (courbe.y.length > 0) {
                values.push(courbe.y[courbe.y.length - 1])
            } else {
                values.push(0)
            }
        }

        let data = [{
            type: "sankey",
            orientation: "h",
            node: {
                pad: 5,
                thickness: 20,
                line: {
                    color: "black",
                    width: 0.5
                },
                legend: 8,
                label: [
                    "SB GROUPE",
                    "SB Fondations",
                    "SB Malet",
                    "SB Génie civil",
                    "SB Valerian",
                    "SB Construction",
                    "Equipement de construction routière",
                    "Equipement de béton",
                    "Equipement de tranchée et d excavation",
                    "Equipement de démolition",
                    "Equipement routier",
                    "Equipement de manutention",
                    "Equipement de terrassement",
                    "Equipement de compactage",
                    "Energie",
                    "Equipement minier",
                    "Equipement de forage et battage",
                    "Equipement de construction spécialisé",
                ],
                color: ["#f0af00", "#f0af00", "#f0af00", "#f0af00", "#f0af00", "#f0af00",
                    "#e786aa",
                    "#a7a7a7",
                    "#c5a289",
                    "#ffa280",
                    "#ffda92",
                    "#80b180",
                    "#8080bf",
                    "#bf80bf",
                    "#5e5e5e",
                    "#bfbf80",
                    "#ced6de",
                    "#80c5c5",
                ]
            },
            link: {
                source: [
                    0, 0, 0, 0, 0,
                    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
                    3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,
                    4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
                    5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                ],
                target: [
                    1, 2, 3, 4, 5,
                    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                ],

                color: [
                    "rgba(240,175,0,0.60)", "rgba(240,175,0,0.60)", "rgba(240,175,0,0.60)", "rgba(240,175,0,0.60)", "rgba(240,175,0,0.60)",
                    "rgba(231,134,170,0.60)", "rgba(167,167,167,0.60)", "rgba(197,162,137,0.60)", "rgba(255,162,128,0.60)", "rgba(255,218,146,0.60)", "rgba(128,177,128,0.60)", "rgba(128,128,191,0.60)", "rgba(191,128,191,0.60)", "rgba(94,94,94,0.60)", "rgba(191,191,128,0.60)", "rgba(206,214,222,0.60)", "rgba(128,197,197,0.60)",
                    "rgba(231,134,170,0.60)", "rgba(167,167,167,0.60)", "rgba(197,162,137,0.60)", "rgba(255,162,128,0.60)", "rgba(255,218,146,0.60)", "rgba(128,177,128,0.60)", "rgba(128,128,191,0.60)", "rgba(191,128,191,0.60)", "rgba(94,94,94,0.60)", "rgba(191,191,128,0.60)", "rgba(206,214,222,0.60)", "rgba(128,197,197,0.60)",
                    "rgba(231,134,170,0.60)", "rgba(167,167,167,0.60)", "rgba(197,162,137,0.60)", "rgba(255,162,128,0.60)", "rgba(255,218,146,0.60)", "rgba(128,177,128,0.60)", "rgba(128,128,191,0.60)", "rgba(191,128,191,0.60)", "rgba(94,94,94,0.60)", "rgba(191,191,128,0.60)", "rgba(206,214,222,0.60)", "rgba(128,197,197,0.60)",
                    "rgba(231,134,170,0.60)", "rgba(167,167,167,0.60)", "rgba(197,162,137,0.60)", "rgba(255,162,128,0.60)", "rgba(255,218,146,0.60)", "rgba(128,177,128,0.60)", "rgba(128,128,191,0.60)", "rgba(191,128,191,0.60)", "rgba(94,94,94,0.60)", "rgba(191,191,128,0.60)", "rgba(206,214,222,0.60)", "rgba(128,197,197,0.60)",
                    "rgba(231,134,170,0.60)", "rgba(167,167,167,0.60)", "rgba(197,162,137,0.60)", "rgba(255,162,128,0.60)", "rgba(255,218,146,0.60)", "rgba(128,177,128,0.60)", "rgba(128,128,191,0.60)", "rgba(191,128,191,0.60)", "rgba(94,94,94,0.60)", "rgba(191,191,128,0.60)", "rgba(206,214,222,0.60)", "rgba(128,197,197,0.60)",
                ],
                value: values
            },
            ...styleData
        }];

        return <BibliothequePlotly
            data={data}
            layout={{
                title: {
                    text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle
                },
                responsive: true,
                width: this.props.dimensionWidth !== undefined ? this.props.dimensionWidth : window.innerWidth * 0.75, //150
                height: this.props.dimensionHeight !== undefined ? this.props.dimensionHeight : window.innerHeight * 0.85, //165
                legend: {
                    x: 1.05,//todo rendre la légende responsive
                    y: 1,
                    borderwidth: 2,
                    entrywidthmode: "pixels",
                    entrywidth: 20,
                },
                modebar: {
                    // vertical modebar button layout
                    orientation: 'h',
                    // for demonstration purposes
                    bgcolor: 'rgba(0, 0, 0, 0)',
                    color: 'black',
                    activecolor: '#f0af00'

                },
                ...jsonLayout,
            }}
            donneesGraphe={this.props.donneesGraphe}
            ouvrirPopupModifierIntervalle={this.props.switchAfficherModifierIntervalle}
            dimensionWidth={this.props.dimensionWidth}
            dimensionHeight={this.props.dimensionHeight}
            viewobj_json_content={this.props.viewobj_json_content}

            config={{
                locales: {'fr': localeDictionary},
                locale: 'fr',
                displayModeBar: this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? true : this.props.viewobj_json_content.length === 0 || this.props.viewobj_json_content.displayModeBar === true ? true : false,
                displaylogo: false,
                modeBarButtonsToRemove: [''],
            }}

        />;
    }
}

export default DiagrammeSankey;
