import React, {Component} from 'react';

import Bouton from "../../../UI/Bouton/Bouton";
import AccesRestreins from "../../../../hoc/AccesRestreins/AccesRestreins";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";
import Collapse from "../../../../hoc/Collapse/Collapse"

import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as privilege from "../../../../ressources/glossaires/glossaire_privileges";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {confirmeOnClick} from "../../../../ressources/functionJS/confirmeOnClick";

import {AiFillEdit, AiFillDelete} from "react-icons/ai";
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {
    sas,
    hedes,
    ConteneurInfo,
    text_profil,
    bts,
    ConteneurProfilVue,
    style,
} from './ProfilRapportGenere.module.scss';
import FormulaireRapportGenere from "./FormulaireRapportGenere/FormulaireRapportGenere";

class ProfilRapportGenere extends Component {
    state = {
        afficherModifier: false,
        fichier_word: "",
    }

    //fonction afficher le formulaire de modification du profil de l'rapport généré
    afficherModifierProfil = () => {
        this.setState({afficherModifier: true});
    }
    //fonction pour annuler la modification du profil de l'rapport généré
    annulerModificationProfil = () => {
        this.setState({...this.state, afficherModifier: false});
    }

    render() {
        const formulaireVue = <div className={[s.col_md_12, ConteneurProfilVue].join(" ")}>
            <div className={text_profil}>
                <FormulaireRapportGenere preconfigurerRapportGenere={this.props.infoRapportGenere}
                                         annuler={this.annulerModificationProfil}
                                         affichageProfil={!this.state.afficherModifier}/>
                {/*charger un fichier*/}
                <div className={[s.row].join(" ")}>
                    {/*<AccesRestreins numPrivilege={privilege.supprimer_des_rapports_generes}>*/}
                    <div className={sas}>
                        <input type="file" onChange={(e) => {
                            this.setState({fichier_word: e.target.files[0]})
                        }} />
                        <Bouton
                            cliquer={() => this.props.reqChargerDocumentRapport(this.props.infoRapportGenere.generate_report_id, this.state.fichier_word)}>
                            <AiFillEdit/> {checkMot("charger")}
                        </Bouton>
                    </div>
                    {/*</AccesRestreins>*/}
                </div>
                {/*Début barre d'outil*/}
                <div className={[s.row, bts].join(" ")}>
                    <AccesRestreins numPrivilege={privilege.modifier_des_rapports_generes}>
                        <div className={sas}>
                            {/*todo: bouton télécharger et charger/téléverser le document word*/}
                            <Bouton
                                desactive={this.props.infoRapportGenere.private === 0}
                                cliquer={() => this.props.reqPublierRapportGenere(this.props.infoRapportGenere.generate_report_id)}>
                                <AiFillEdit/> {this.props.infoRapportGenere.private === 1 ? checkMot("publier"): checkMot("publie")}
                            </Bouton>
                        </div>
                    </AccesRestreins>
                    <AccesRestreins numPrivilege={privilege.supprimer_des_rapports_generes}>
                        <div className={sas}>
                            <AttenteRequete request_type={requestType.supprimer_un_rapport_genere}>
                                <Bouton
                                    cliquer={() => confirmeOnClick(checkMot("voulez_vous_vraiment_supprimer_ce_rapport_genere"), () => this.props.reqSupprimer(this.props.infoRapportGenere.generate_report_id))}>
                                    <AiFillDelete/> {checkMot("supprimer")}
                                </Bouton>
                            </AttenteRequete>
                        </div>
                    </AccesRestreins>
                </div>
                {/*Fin barre d'outil*/}
            </div>
        </div>
        return (
            <div className={ConteneurInfo}>
                {/*entête du profil rapport généré*/}
                <div className={[s.card_header, hedes].join(" ")}>
                    {/* logo "i"*/}
                    <span className={style}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle-fill"
                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>  
                    </span>
                    <span className={style}>
                    {/*l'id de l'rapport généré*/}
                        {checkMot("generate_report_id")} : {this.props.infoRapportGenere.generate_report_id}
                    </span>
                </div>
                {/*conteneur pour l'affichage général du conteneur info*/}
                <div className={s.container}>
                    <Collapse showAdvanceParameters={true} ExpandText={checkMot("info_general")}>
                        {formulaireVue}
                    </Collapse>
                </div>
            </div>
        )

    }
}

export default ProfilRapportGenere;


