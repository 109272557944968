export const image = 1;
export const texte = 2;
export const date = 3;
export const section = 10;
export const sous_section = 11;
export const graphe = 12;
export const tableau_composant = 13;
export const tableau_alarme = 14;
export const boite = 15;
export const vue = 16;

export const objet_json = {
    [image]: {
        "__comment": "type image",
        "report_object_type": image,
        "pagebreak": false,
        "zero_margin": false,
        "image_path": "./test.svg",
        "align": "left",
        "width": 100,
        "height": 100,
        "crop_x": 0,
        "crop_y": 0,
        "crop_width": 0,
        "crop_height": 0
    },
    [texte]: {
        "__comment": "type texte",
        "__comment_ref": "https://developer.mozilla.org/fr/docs/Web/CSS/text-align",
        "pagebreak": false,
        "report_object_type": texte,
        "text": "more texte",
        "align": "both",
        "font_style": {
            "size": 10,
            "bold": false,
            "italic": false,
            "underline": false
        }

    },
    [date]: {
        "__comment": "type date",
        "report_object_type": date,
        "pagebreak": false,
        "type_date": "publication",
        "format_date": "jj/mm/aaaa",
        "prefix_date": "Publication: ",
        "suffixe_date": "/n",
        "align": "left"
    },
    [section]: {
        "__comment": "type section",
        "report_object_type": section,
        "pagebreak": false,
        "section_name": "Nom de section",
        "font_style": {
            "size": 16,
            "color": "eeb214",
            "bold": false,
            "italic": false,
            "underline": false
        },
        "objects_list": []
    },
    [sous_section]: {
        "__comment": "type sous-section",
        "report_object_type": sous_section,
        "pagebreak": false,
        "sub_section_name": "Nom de sous-section",
        "font_style": {
            "size": 16,
            "color": "eeb214",
            "bold": false,
            "italic": false,
            "underline": false
        },
        "objects_list": []
    },
    [graphe]: {
        "__comment": "type graphe",
        "report_object_type": graphe,
        "graph_id": null,
        "pagebreak": false,
        "zero_margin": false,
        "align": "left",
        "width": 450,
        "height": 310,
        "crop_x": 0,
        "crop_y": 0,
        "crop_width": 0,
        "crop_height": 0
    },
    [tableau_composant]: {
        "__comment": "type tableau",
        "report_object_type": tableau_composant,
        "pagebreak": false,
        "sensors_list": [],
        "n_cols": 4,
        "width": 100,
        "height": 100,
        "get_data_from_beginning": 0
    },
    [tableau_alarme]: {
        "__comment": "type tableau",
        "report_object_type": tableau_alarme,
        "pagebreak": false,
        "project_id_list": [],
        "sensors_list": [],
        "width": 100,
        "height": 100
    },
    [boite]: {
        "__comment": "type boîte",
        "report_object_type": boite,
        "pagebreak": false,
        "align": "left",
        "display": "horizontal",
        "objects_list": []
    },
    [vue]: {
        "__comment": "type vue",
        "report_object_type": vue,
        "view_id": null,
        "pagebreak": false,
        "zero_margin": false,
        "align": "left",
        "width": 450,
        "height": 310,
        "crop_x": 0,
        "crop_y": 0,
        "crop_width": 0,
        "crop_height": 0
    },
};
