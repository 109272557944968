export const graphe_automatique = 0;
export const graphe_x_t = 1;
export const graphe_x_y = 2;
export const graphe_x_y_t = 3;
export const graphe_avancement_detaille = 4;
export const graphes_avancement_jour_semaine_mois = 5;
export const historique_geo_positions = 6;
export const graphes_machine_position = 7;
export const graphes_2 = 8;
export const graphes_3 = 9;
export const graphes_4 = 10;
export const graphe_geoposition = 11;
export const tableau_dernieres_valeurs_et_totaux_rapport = 12;
export const tableau_delta_des_bornes_et_totaux_rapport = 13;
export const tableau_dernieres_valeurs_et_totaux_responsable = 14;
export const tableau_delta_des_bornes_et_totaux = 15;
export const tableau_dernieres_valeurs_et_totaux = 16;
export const tableau_min_max_date = 17;
export const tableau_absolute_min_max_date = 18;
export const graphe_circulaire = 19;
export const diagramme_sankey = 20;
export const gauge_demi_cercle = 21;

/*
checkMot("graphe_automatique")
checkMot("graphe_x_t")
checkMot("graphe_x_y")
checkMot("graphe_x_y_t")
checkMot("graphe_avancement_detaille")
checkMot("graphes_avancement_jour_semaine_mois")
checkMot("historique_geo_positions")
checkMot("graphes_machine_position")
checkMot("graphes_2")
checkMot("graphes_3")
checkMot("graphes_4")
checkMot("graphe_geoposition")
checkMot("tableau_dernieres_valeurs_et_totaux_rapport")
checkMot("tableau_delta_des_bornes_et_totaux_rapport")
checkMot("tableau_dernieres_valeurs_et_totaux_responsable")
checkMot("tableau_delta_des_bornes_et_totaux")
checkMot("tableau_dernieres_valeurs_et_totaux")
checkMot("tableau_min_max_date")
checkMot("tableau_absolute_min_max_date")
checkMot("graphe_circulaire")
checkMot("diagramme_sankey")
checkMot("gauge_demi_cercle")
 */